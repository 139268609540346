import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { API_ROOT } from 'api-config';
import ReactLoading from 'react-loading';

import ResultItem from 'Pages/Search/components/Results/ResultItem';
import Posting from 'Pages/Posting/Posting';

import './Preview.scss';

const Preview = (props) => {
  const { userUuid } = props;
  const { uuid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(
        API_ROOT + `/api/job/preview/${uuid}/${userUuid}`
      );
      setPreviewData(data);
      setIsLoading(false);
    }

    fetchData();
  }, [userUuid, uuid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    async function postData() {
      const { data } = await axios.post(
        API_ROOT + `/api/job/publish/${uuid}/${userUuid}`
      );

      if (data.error) {
        return toast.error(data.message);
      } else {
        return toast.success(data.message);
      }
    }

    postData();
  };

  if (isLoading) {
    return (
      <div className="base-loading-container">
        <ReactLoading type="spin" color="#333" />
      </div>
    );
  }
  return (
    <div className="preview-container">
      <ul className="base-info-list">
        <li>You are looking at your tile, which appears in the search </li>
        <li>
          Underneath the tile is your page, which will be seen after the user
          clicks on the tile
        </li>
        <li>Click the blue circles above to return to previous step</li>
        <li>
          <strong>
            The Publish Button is at the bottom. Click it to make your job
            posting live
          </strong>
          .
        </li>
      </ul>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="result-item-container">
          <ResultItem {...previewData} publishedDate={new Date()} />
        </div>
        <div className="divider"></div>

        <Posting job={{ ...previewData }} />
        <button type="submit" className="btn btn-blue publish-btn">
          Publish
        </button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userUuid: state.app.currentUser.uuid,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
