import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_ROOT } from 'api-config';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import fanPng from '../../assets/fan.png';

import './ForgotPassword.scss';

const ForgotPassword = (props) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      const { data } = await axios.get(
        API_ROOT + `/api/forgot-password?email=${values.email}`
      );
      if (data.error) {
        return toast.error(data.message);
      }

      return toast.success(data.message);

      // Here is where I want to do the toast notification
      // I also want to have it have a lifespan longer of that of other oens
      // I think I should sennd them to the homepage props.history.push("/")
    },
  });

  return (
    <Fragment>
      <h1 className="base-header-styling">Forgot Password</h1>
      <form className="forgot-form" onSubmit={formik.handleSubmit}>
        <img className="logo" src={fanPng} alt="logo" />
        <div className="input-container">
          <label htmlFor="email">
            <input
              type="email"
              name="email"
              id="email"
              className="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              placeholder="Email"
            />
          </label>
          {formik.touched.email && formik.errors.email && (
            <div className="error-form">{formik.errors.email}</div>
          )}
        </div>

        <button className="submit-button" type="submit">
          Send email
        </button>
        <Link className="login-link" to="/login">
          Or just log in?
        </Link>
      </form>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
