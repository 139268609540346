export const countryOptions = [
  { label: 'China', value: 'china' },
  { label: 'Indonesia', value: 'indonesia' },
  { label: 'Japan', value: 'japan' },
  { label: 'South Korea', value: 'south-korea' },
  { label: 'Taiwan', value: 'taiwan' },
  { label: 'Thailand', value: 'thailand' },
  { label: 'Vietnam', value: 'vietnam' },
];

export const startDateOptions = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

export const classSizeOptions = [
  { label: '1 - 10', value: '1 - 10' },
  { label: '11 - 20', value: '11 - 20' },
  { label: '21 - 30', value: '21 - 30' },
  { label: 'Greater than 30', value: 'Greater than 30' },
];

export const ageGroupOptions = [
  { label: 'Children', value: 'Children' },
  { label: 'Adolescents', value: 'Adolescents' },
  { label: 'University', value: 'University' },
  { label: 'Adults', value: 'Adults' },
];

export const durationOptions = [
  { label: '1 Month', value: '1' },
  { label: '2 Months', value: '2' },
  { label: '3 Months', value: '3' },
  { label: '4 Months', value: '4' },
  { label: '5 Months', value: '5' },
  { label: '6 Months', value: '6' },
  { label: '7 Months', value: '7' },
  { label: '8 Months', value: '8' },
  { label: '9 Months', value: '9' },
  { label: '10 Months', value: '10' },
  { label: '11 Months', value: '11' },
  { label: '1 Year', value: '12' },
  { label: 'More than 1 year', value: '13' },
];

export const initialValues = {
  companyName: '',
  email: '',
  country: '',
  city: '',
  pay: '',
  ageGroup: '',
  duration: '',
  classSize: '',
  startDate: '',
  phone: '',
  link: '',
};
